export const NavData = [
    {
        title: 'About',
        path: 'about'
    },
    {
        title: 'Services',
        path: 'services'
    },
    {
        title: 'Contact',
        path: 'contact'
    },
    {
        title: 'Our Work',
        path: 'work'
    },
]