import { HomepageView } from "./sections/homepage/views";

function App() {
  return (
    <>
      <HomepageView />
    </>
  );
}

export default App;
