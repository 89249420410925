import React from 'react'
import Button from '../../components/ui-components/Button'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import { handleScroll } from '../../utils/utils'

const Services = () => {
  return (
    <motion.div initial="hidden" whileInView="visible" viewport={{once:true, amount:0.1}} transition={{delay:0.2,duration:0.5}} variants={{hidden:{opacity: 0, y: 50}, visible:{opacity: 1, y: 0}}} className='text-white' id='services'>
        <h2 className='font-space font-bold text-[45px] leading-[52px] text-center'>What We Do</h2>

        <div className='flex justify-center items-center w-full mt-24 px-2 md:px-16 lg:px-16 xl:px-16 2xl:px-28'>
            <div className='flex flex-col gap-6 w-full justify-center'>
                <div className='grid grid-cols-12 gap-6 w-full justify-items-center h-auto'>
                    <div className='flex flex-col xl:col-span-8 col-span-12 gap-6'>
                        <div className='grid grid-cols-2 gap-6 w-full'>

                            <div className='w-full col-span-2 md:col-span-1 h-[300px] hover:scale-105 transition duration-500 border-2 border-white rounded-lg shadow-[4px_4px_30px_5px] shadow-primary bg-gradient-to-tr from-primary via-primary to-white'>
                                <div className='-translate-x-[5.8rem] -translate-y-24'><img src="/assets/homepage/01.png" alt="design" /></div>
                                <div className='px-10 p-4 -translate-y-44 font-inter'>
                                    <h3 className='text-[22px] font-extrabold'>Design</h3>
                                    <p className='text-[18px]'>From UX/UI design to brand identity, we craft visually compelling experiences that resonate with your audience.</p>
                                </div>
                            </div>

                            <div className='w-full col-span-2 md:col-span-1 h-[300px] hover:scale-105 transition duration-500 border-2 border-white rounded-lg shadow-[4px_4px_30px_5px] shadow-primary bg-gradient-to-tr from-primary via-primary to-white'>
                                <div className='w-full flex justify-end'><img src="/assets/homepage/02.png" alt="development" className='translate-x-10 -translate-y-12 md:-translate-y-[5.5rem]' /></div>
                                <div className='px-10 p-4 -translate-y-32 font-inter'>
                                    <h3 className='text-[22px] font-extrabold'>Development</h3>
                                    <p className='text-[18px]'>Our development team builds robust, scalable, and secure applications tailored to your business needs.</p>
                                </div>
                            </div>
                        </div>

                        <div className='w-full h-[210px] hover:scale-105 transition duration-500 border-2 border-white rounded-lg shadow-[4px_4px_30px_5px] shadow-primary bg-gradient-to-r from-white via-primary to-primary flex items-center'>
                            <div className='lg:-translate-x-24 md:-translate-x-20 w-1/3'><img src="/assets/homepage/04.png" alt="deployment" /></div>
                            <div className='px-4 p-4 font-inter w-full'>
                                <h3 className='text-[22px] font-extrabold'>Deployment</h3>
                                <p className='text-[18px]'>We ensure a smooth and efficient launch, managing everything from hosting to post-launch support.</p>
                            </div>
                        </div>
                    </div>

                    <div className='w-full xl:h-[535px] h-fit md:h-[340px] xl:col-span-4 col-span-12 hover:scale-105 transition duration-500 border-2 border-white rounded-lg shadow-[4px_4px_30px_5px] shadow-primary bg-gradient-to-tr from-primary via-primary to-white flex md:flex-col items-center xl:gap-10 z-40'>
                            <div className='md:w-full w-[30%] flex justify-start xl:justify-center -translate-x-10 md:-translate-x-0'><img src="/assets/homepage/03.png" alt="development" className='w-56 md:-translate-y-[3rem] md:-translate-x-[6rem] xl:translate-x-0 xl:-translate-y-[6rem]' /></div>
                            <div className='md:px-8 p-4 w-[70%] md:w-full md:-translate-y-32 font-inter md:mt-10 py-2'>
                                <h3 className='text-[22px] font-extrabold'>Talent Acquisition</h3>
                                <p className='text-[22px]'>Hire the Right Talent</p>
                                <p className='text-[18px]'>We train and nurture talent in design, development, and other tech-related fields. Our internship program grooms potential employees for your startup. You can hire trained, vetted professionals who are ready to contribute from day one.</p>
                            </div>
                    </div>
                    
                </div>

                <div className='lg:h-[164px] w-full hover:scale-105 transition duration-500 border-2 border-white rounded-lg shadow-[4px_4px_30px_5px] shadow-primary bg-gradient-to-r from-white via-primary to-primary flex flex-col md:flex-row justify-end md:items-center items-end'>
                    <div className='md:w-1/3 w-full'><img src="/assets/homepage/05.png" alt="startup" className='md:translate-y-14 -translate-y-4 w-1/2 md:w-fit' /></div>
                    <div className='w-2/3'>
                        <h3 className='text-[22px] font-extrabold'>Startup Support</h3>
                        <p className='text-[18px]'>We provide ongoing support, including performance tracking, updates, and growth strategies.</p>
                    </div>
                </div>

            </div>
        </div>
    </motion.div>
  )
}

export default Services