import React from 'react';
import { IoIosMail } from "react-icons/io";
import { HiLocationMarker } from "react-icons/hi";
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

const Contact = () => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <motion.div 
      initial="hidden" 
      whileInView="visible" 
      viewport={{ once: true, amount: 0.1 }} 
      transition={{ delay: 0.2, duration: 0.5 }} 
      variants={{ hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0 } }} 
      className="shadow-[4px_4px_40px_5px] shadow-primary" 
      id="contact"
    >
      <div className="bg-white rounded-lg shadow-[inset_2px_2px_20px_-1px] shadow-primary mt-28 p-6 md:p-10 lg:p-10 h-auto flex flex-col items-center mb-[500px]">
        
        {/* Heading */}
        <div className="w-full lg:w-[45%] px-4 flex flex-col gap-6 items-center mt-10 lg:mt-36">
          <h2 className="font-space font-bold text-[24px] sm:text-[30px] lg:text-[45px] leading-[32px] lg:leading-[52px] text-center text-primary">
            {"Let's Get Started"}
          </h2>
          <p className="font-inter leading-[24px] text-center text-[14px] lg:text-[16px]">
            Ready to turn your idea into reality?<br />
            Need the right team to support your growth? Get in touch with us to discuss your project and hiring needs.
          </p>
        </div>

        {/* Conditional Rendering for Desktop and Mobile */}
        {isDesktop ? (
          // Desktop Layout
          <div className='flex items-center mx-auto w-full px-16 mt-52'>
                <div className='w-[900px] h-[840px] bg-gradient-to-br from-primary via-primary to-white rounded-lg border-2 border-white shadow-[3px_3px_30px_2px] shadow-primary py-32 px-12 flex flex-col text-white font-inter'>
                  <div className='h-1/2 w-2/3'>
                    <h3 className='font-space text-[32px] leading-[40px] font-[400]'>{"Let’s"} discuss<br/> on something cool together</h3>
                  </div>

                  <div className='flex flex-col justify-center gap-16'>
                    <div className='flex items-center gap-4 font-[500] px-4'>
                      <IoIosMail size={28} />
                      <p className='text-[14px] leading-[20px]'>{"sales@techovelpo.com"}</p>
                    </div>

                    {/*<div className=' px-4 py-4 flex items-center gap-4  font-[500]'>
                      <BsFillTelephoneFill size={24} />
                      <p className='text-[14px] leading-[20px]'>{"+91-7428989379"}</p>
                    </div>*/}

                    <div className='flex items-center gap-4 font-[500] px-4'>
                      <HiLocationMarker size={28} />
                      <p>India :</p><p className='text-[14px] leading-[30px] font-poppins'>{"No. 5 Whisteling Greens, Hobli 3 Bangalore"}</p>
                    </div>
                    <div className='flex items-center gap-4 font-[500] px-4'>
                      <HiLocationMarker size={28} />
                      <p>Australia :</p><p className='text-[14px] leading-[30px] font-poppins'>{"11-31 York Street, Sydney"}</p>
                    </div>
                    <div className='flex items-center gap-4 font-[500] px-4'>
                      <HiLocationMarker size={28} />
                      <p>USA :</p><p className='text-[14px] leading-[30px] font-poppins'>{"28112 Plantation Dr. NE Atlanta, GA"}</p>
                    </div>
                  </div>
                </div>

                <div className='w-[700px] h-[870px] bg-[#EEEEEE] rounded-2xl absolute translate-x-[36rem] -translate-y-1.5 p-12'>
                <iframe
        src="https://forms.gle/n7hLJTcjecvCYwMS7"
        title="Contact Form"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
                  <img src="/assets/homepage/contact.png" alt="contact" className='absolute right-0 -translate-y-64' />
                </div>
            </div>
        ) : (
          // Mobile Layout
          <div className="flex flex-col items-center w-full px-4 mt-16 gap-10">
            {/* Contact Form */}
            <div className="w-full bg-[#EEEEEE] rounded-2xl p-6">
              <iframe
                src="https://forms.gle/n7hLJTcjecvCYwMS7"
                title="Contact Form"
                width="100%"
                height="400px"
                style={{ border: 'none' }}
              />
            </div>

            {/* Contact Information */}
            <div className="w-full bg-gradient-to-br from-primary via-primary to-white rounded-lg border-2 border-white shadow-[3px_3px_30px_2px] shadow-primary py-8 px-6 flex flex-col text-white font-inter">
              <h3 className="font-space text-[24px] leading-[32px] font-[400] text-center">
                {"Let’s discuss something cool together"}
              </h3>
              <div className="flex flex-col gap-6 mt-4 items-center">
                <div className="flex items-center gap-4 font-[500]">
                  <IoIosMail size={24} />
                  <p className="text-[14px] leading-[20px]">{"sales@techovelpo.com"}</p>
                </div>
                <div className="flex items-center gap-4 font-[500]">
                  <HiLocationMarker size={24} />
                  <p>India:</p><p className="text-[14px] leading-[24px] font-poppins">{"No. 5 Whisteling Greens, Hobli 3 Bangalore"}</p>
                </div>
                <div className="flex items-center gap-4 font-[500]">
                  <HiLocationMarker size={24} />
                  <p>Australia:</p><p className="text-[14px] leading-[24px] font-poppins">{"11-31 York Street, Sydney"}</p>
                </div>
                <div className="flex items-center gap-4 font-[500]">
                  <HiLocationMarker size={24} />
                  <p>USA:</p><p className="text-[14px] leading-[24px] font-poppins">{"28112 Plantation Dr. NE Atlanta, GA"}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default Contact;
