import React from 'react'
import { NavLink } from 'react-router-dom';
import { NavData } from './navdata';
import Button from '../ui-components/Button';
import { IoMenu } from "react-icons/io5";
import { handleScroll } from '../../utils/utils';
import Drift from 'react-driftjs';
const Navbar = () => {


  return (
    <div className='flex justify-between items-center px-10 py-4'>
        <div>
            <NavLink to="/">
                <img src="./logo1.png" alt="Tech-Ovelpo" style={{height:120}} />
            </NavLink>
        </div>

        <nav className='lg:flex hidden justify-center items-center gap-10'>
            {NavData.map((item, index) => (
                <NavLink onClick={(e) => handleScroll(e, item.path)} className='text-base leading-[19px] font-medium' key={item.title} to={item.path}>{item.title}</NavLink>
            ))}
        </nav>

        <div className='lg:hidden block text-primary'>
            <IoMenu size={34} />
        </div>
        
    </div>
  )
}

export default Navbar