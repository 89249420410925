import React from 'react'
import Button from '../../components/ui-components/Button'
import { motion } from 'framer-motion'

const HiringSection = () => {
  return (
    <motion.div initial="hidden" whileInView="visible" viewport={{once:true, amount:0.1}} transition={{delay:0.2,duration:0.5}} variants={{hidden:{opacity: 0, y: 50}, visible:{opacity: 1, y: 0}}} className='flex flex-col items-center justify-center gap-8 text-white mt-28' id='talent'>
        <div className='flex flex-col items-center gap-8 lg:w-[70%] md:w-[85%] w-full'>
            <h2 className='font-space font-bold md:text-[45px] md:leading-[52px] text-4xl text-center'>Trained Professionals, Ready to Join Your Team</h2>
            <p className='font-inter md:leading-[24px] text-center md:px-10'>Our rigorous internship program ensures that the talent we produce is not only skilled but also understands the fast-paced demands of a startup environment. Whether you need a developer, designer, or tech specialist, we have the right person for your team</p>
        </div>
        <div className='flex justify-center lg:w-[70%] md:w-[85%] w-full mt-8'>
            <img src="/assets/homepage/hiring.png" className='rounded-lg border-2 border-white shadow-[4px_4px_30px_10px] shadow-primary' alt="hiring" />
        </div>
    </motion.div>
  )
}

export default HiringSection