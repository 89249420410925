import React from 'react'
import HeroSection from '../HeroSection'
import Services from '../Services'
import HiringSection from '../HiringSection'
import WorkSection from '../WorkSection'
import Features from '../Features'
import Testimonials from '../Testimonials'
import Contact from '../Contact'
import Footer from '../../../components/Footer'
import Drift from 'react-driftjs'

const HomepageView = () => {
  return (
    <div className='xl:container mx-auto p-6 font-inter overflow-hidden'>
      <Drift appId="xrkazms6atne" />
        <HeroSection />
        <Services />
        <HiringSection />
        <WorkSection />
        <Features />
        <Testimonials />
        <Contact />
        <Footer />
    </div>
  )
}

export default HomepageView