import React from 'react'
import Button from '../../components/ui-components/Button'
import { FaCircleArrowDown } from "react-icons/fa6";
import { motion } from 'framer-motion';

const WorkSection = () => {

    const Card = ({variant,title, body,imageSource}) => (
        <div className='flex flex-col md:flex-row gap-4 items-center justify-center'>
            <div className={`lg:w-[35%] md:w-[50%] w-full flex flex-col md:gap-36 gap-6 md:px-16 px-10 ${variant === 'start' ? 'md:order-1 order-2 md:text-start text-center' : 'order-2 md:text-end text-center'}`}>
                <h3 className='font-inter font-[900] text-[22px] leading-[28px]'>{title}</h3>

                <div className={`flex flex-col gap-8 ${variant === 'start' ? 'md:items-start items-center md:text-start text-center' : 'md:items-end items-center md:text-end text-center'}`}>
                    <p className='font-inter font-[400] text-[18px] leading-[24px]'>
                        {body}
                    </p>
                </div>
            </div>

            <div className={`flex justify-end items-center lg:w-[65%] md:w-[50%] ${variant === 'start' ? 'md:order-2 order-1 md:translate-x-10' : 'order-1 md:-translate-x-10'} hover:scale-105 transition duration-500`}>
                <img src={imageSource} alt="work"/>
            </div>
        </div>
    )

  return (
    <motion.div initial="hidden" whileInView="visible" viewport={{once:true, amount:0.1}} transition={{delay:0.2,duration:0.5}} variants={{hidden:{opacity: 0, y: 50}, visible:{opacity: 1, y: 0}}} className='shadow-[4px_4px_30px_5px] shadow-primary' id='work'>
        <div  className='bg-white rounded-lg shadow-[inset_2px_2px_20px_-1px] shadow-primary mt-28 md:p-10 py-4 flex flex-col items-center gap-8'>
            <h2 className='font-space font-bold text-[45px] leading-[52px] text-center text-primary p-10'>Our Work Speaks for Itself</h2>

            <div className='flex flex-col justify-center items-center gap-10'>
                <Card variant='start' 
                title='Innovative Solutions' 
                body="At Techovelpo, we design forward-thinking SaaS solutions that solve today’s toughest challenges. Our team combines industry insights with the latest technology to deliver transformative products tailored to your business. We believe in creating impactful results, whether it's streamlining operations, enhancing customer engagement, or boosting efficiency. Explore our portfolio to see how we’ve empowered businesses across sectors to achieve their goals with innovation at the core." 
                imageSource='/assets/homepage/techovelpo-innovation.png'
                />
                <Card variant='end'
                title="Client-Centric Approach"
                body="Your success is our mission. Techovelpo is dedicated to understanding your unique needs and delivering solutions that truly align with your objectives. We build strong partnerships by actively listening to our clients, ensuring each product is customized to exceed expectations. Our client-centric approach means you’re always at the heart of our service – from initial consultation to ongoing support, we’re here to make your journey with Techovelpo seamless and successful."
                imageSource="/assets/homepage/techovelpo-client.png"
                />
                <Card variant='start'
                title="Scalable & Reliable Products"
                body="Techovelpo's SaaS solutions are engineered for scalability and dependability, supporting businesses from startups to enterprises. Our products are designed to grow with you, ensuring seamless performance even as your business evolves. With robust security protocols and minimal downtime, you can trust our solutions to keep your operations running smoothly and securely. Experience the reliability and flexibility of SaaS products built to fit your business, now and in the future."
                imageSource="/assets/homepage/scalable.png"
                />
            </div>
        </div>
    </motion.div>
  )
}

export default WorkSection
