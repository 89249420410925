import React from 'react';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { timeAgo } from '../../utils/utils';

const Testimonials = () => {

    const Card = ({ direction, name, content, time }) => (
        <div className={`flex flex-col hover:scale-105 transition duration-500 items-center font-inter ${direction === 'down' ? 'xl:translate-y-80' : direction === 'up' ? 'xl:-translate-y-48' : ''}`}>
            <img src="/assets/homepage/t1.png" alt="testimonial" className='w-[80px] sm:w-[100px]' />

            <div className='flex flex-col gap-4 sm:gap-6 bg-gradient-to-b from-white via-primary to-primary w-[200px] h-[320px] border-2 border-white rounded-lg shadow-[4px_4px_40px_5px] shadow-primary px-4 sm:px-6 py-3 items-center'>
                <div className='flex items-center gap-2'>
                    <img src="/assets/homepage/avatar.png" alt="avatar" className='rounded-full w-10 h-10' />
                    <div className='flex flex-col justify-center'>
                        <h5 className='text-black font-[500] leading-[24px]'>{name}</h5>
                        <p className='text-black/55 font-[500] text-[10px] sm:text-[12px] leading-[14px] sm:leading-[16px]'>{time}</p>
                    </div>
                </div>

                <p className='font-[400] text-[10px] sm:text-[12px] leading-[14px] sm:leading-[16px]'>{content}</p>
            </div>
        </div>
    );

    return (
        <motion.div 
            initial="hidden" 
            whileInView="visible" 
            viewport={{ once: true, amount: 0.2 }} 
            transition={{ delay: 0.2, duration: 0.5 }} 
            variants={{ hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0 } }} 
            className='text-white pt-12'  // Added padding-top for mobile view
        >
            <div>
                <h2 className='font-space font-bold text-[24px] sm:text-[30px] lg:text-[45px] text-center mt-40 sm:mt-10'>
                    What Our Clients Say
                </h2>
            </div>
            <div className='flex flex-col xl:flex-row items-center xl:justify-center xl:h-[1200px] py-10 mt-8 sm:mt-12'>
                <Card direction='mid' name="Samantha Lee" content="Techovelpo has transformed our business operations. Their software is intuitive, reliable, and has streamlined our workflows significantly. The team is incredibly responsive and truly understands our needs. Highly recommend!" time={timeAgo(new Date('2023-04-30'))} />
                <Card direction='down' name="Michael Harris" content="The Techovelpo platform has been a game-changer for us. The level of customization and the ease of use make it an essential part of our daily processes. Their support team is fantastic, always there when we need them." time={timeAgo(new Date('2024-06-30'))} />
                <Card direction='up' name="Bruce Evans" content="We’ve tried several SaaS solutions, but Techovelpo stands out. The software is fast, reliable, and packed with features that cater to our specific industry needs. Excellent product and top-notch customer service!" time={timeAgo(new Date('2022-01-15'))} />
                <Card direction='down' name="Melanie Cooper" content="Switching to Techovelpo was the best decision we made this year. Their platform has boosted our productivity, and their team is always open to feedback and improvements. Very satisfied with the service" time={timeAgo(new Date('2021-11-10'))} />
                <Card direction='mid' name="Carlos Rivera" content="Techovelpo is the perfect partner for any growing business. Their tools are powerful yet user-friendly, and the support team genuinely cares about our success. Can’t imagine working without it!" time={timeAgo(new Date('2020-12-03'))} />
            </div>

            <div className='flex flex-wrap items-center gap-8 justify-between mt-12 sm:mt-20 lg:mt-28 px-4 sm:px-20'>
                <div className='flex flex-col items-center sm:items-start'>
                    <div className='flex items-center font-manrope font-[800] text-2xl sm:text-4xl lg:text-[58px] lg:leading-[76px]'>
                        <CountUp end={100} duration={4} delay={1} />
                        <h4>+</h4>
                    </div>
                    <p className='font-manrope font-[600] text-sm sm:text-lg opacity-70'>Professionals</p>
                </div>

                <div className='flex flex-col items-center sm:items-start'>
                    <div className='flex items-center font-manrope font-[800] text-2xl sm:text-4xl lg:text-[58px] lg:leading-[76px]'>
                        <CountUp end={5} duration={4} delay={1} />
                        <h4>+</h4>
                    </div>
                    <p className='font-manrope font-[600] text-sm sm:text-lg opacity-70'>Years Experience</p>
                </div>

                <div className='flex flex-col items-center sm:items-start'>
                    <div className='flex items-center font-manrope font-[800] text-2xl sm:text-4xl lg:text-[58px] lg:leading-[76px]'>
                        <CountUp end={3} duration={4} delay={1} />
                        <h4>K+</h4>
                    </div>
                    <p className='font-manrope font-[600] text-sm sm:text-lg opacity-70'>Services Closed</p>
                </div>

                <div className='flex flex-col items-center sm:items-start'>
                    <div className='flex items-center font-manrope font-[800] text-2xl sm:text-4xl lg:text-[58px] lg:leading-[76px]'>
                        <CountUp end={100} duration={4} delay={1.5} />
                        <h4>%</h4>
                    </div>
                    <p className='font-manrope font-[600] text-sm sm:text-lg opacity-70'>Customer Satisfaction</p>
                </div>
            </div>
        </motion.div>
    );
};

export default Testimonials;
