import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='w-full h-fit relative px-4 md:px-0'>
        <div className='w-full h-auto md:h-[470px] absolute left-0 bottom-0 border-2 border-white rounded-lg bg-gradient-to-tr from-primary via-primary to-white shadow-[4px_4px_30px_10px] shadow-primary flex flex-col items-center justify-center gap-10 p-8 md:px-32 text-white font-inter'>
            <div className='flex flex-col md:flex-row justify-between w-full md:w-5/6 gap-10 md:gap-0'>
                <div className='flex justify-center md:justify-start'>
                    <img src="/logo2.png" alt="logo" className="h-16 md:h-28" />
                </div>

                <div>
                    <h5 className='font-semibold text-lg'>Use Cases</h5>
                    <ul className='mt-5 md:mt-10 space-y-2 text-sm md:text-base'>
                        <li><NavLink to='/'>Web-designers</NavLink></li>
                        <li><NavLink to='/'>Marketers</NavLink></li>
                        <li><NavLink to='/'>Developers</NavLink></li>
                        <li><NavLink to='/'>Website Builders</NavLink></li>
                    </ul>
                </div>

                <div>
                    <h5 className='font-semibold text-lg'>Company</h5>
                    <ul className='mt-5 md:mt-10 space-y-2 text-sm md:text-base'>
                        <li><NavLink to='/'>About Us</NavLink></li>
                        <li><NavLink to='/'>Careers</NavLink></li>
                        <li><NavLink to='/'>FAQs</NavLink></li>
                        <li><NavLink to='/'>Teams</NavLink></li>
                        <li><NavLink to='/'>Contact Us</NavLink></li>
                    </ul>
                </div>

                <div className='flex flex-col gap-10 md:gap-16'>
                    <div>
                        <h5 className='font-semibold text-lg'>{"Proven Foundation"}</h5>
                        <div className='flex items-center gap-2 md:gap-4 mt-2 flex-wrap justify-center md:justify-start'>
                            <NavLink to='/'><img src="/assets/homepage/google.png" alt="google techovelpo software service" className="h-6 md:h-6" /></NavLink>
                            <NavLink to='/'><img src="/assets/footer/kpmg.png" alt="fox" className="h-8 md:h-12" /></NavLink>
                            <NavLink to='/'><img src="/assets/footer/IBM.png" alt="IBM" className="h-6 md:h-8" /></NavLink>
                            <NavLink to='/'><img src="/assets/footer/paypal.png" alt="azure techovelpo technology partner" className="h-5 md:h-6" /></NavLink>
                            <NavLink to='/'><img src="/assets/footer/apple.png" alt="aws supports techovelpo for its resource related supplies" className="h-8 md:h-12" /></NavLink>
                        </div>
                    </div>

                    <div>
                        <h5 className='font-semibold text-lg'>{"Powered By"}</h5>
                        <div className='flex items-center gap-2 md:gap-4 mt-2 flex-wrap justify-center md:justify-start'>
                            <NavLink to='/'><img src="/assets/footer/github.png" alt="github" className="h-6 md:h-6" /></NavLink>
                            <NavLink to='/'><img src="/assets/homepage/google.png" alt="google techovelpo software service" className="h-6 md:h-6" /></NavLink>
                            <NavLink to='/'><img src="/assets/homepage/azure.png" alt="azure techovelpo technology partner" className="h-8 md:h-8" /></NavLink>
                            <NavLink to='/'><img src="/assets/homepage/web.png" alt="aws supports techovelpo for its resource related supplies" className="h-10 md:h-12" /></NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-center md:justify-end w-full'><p className='font-inter font-[500] text-[12px] md:text-[14px] leading-[20px]'>© 2024 All Rights Reserved</p></div>
        </div>
    </div>
  )
}

export default Footer
