import { motion } from 'framer-motion';
import React from 'react';

const Features = () => {

  const Card = ({image, title}) => (
    <div className='flex flex-col items-center gap-10 hover:scale-105 transition duration-500'>
      <img src={`/assets/homepage/${image}.png`} alt="solutions" className='w-fit lg:h-48 md:h-36 h-24' />
      <h4 className='font-inter font-extrabold md:text-[22px] md:leading-[28px] text-base text-primary'>{title}</h4>
    </div>
  )

  return (
    <div className='relative h-[750px]'> 
    <motion.div initial="hidden" whileInView="visible" viewport={{once:true, amount:0.1}} transition={{delay:0.2,duration:0.5}} variants={{hidden:{opacity: 0, y: 50}, visible:{opacity: 1, y: 0}}} className='w-[100vw] absolute -left-5 bg-white mt-20 flex justify-center p-10'>
        <div className='flex flex-col items-center gap-10'>
          <h2 className='font-space font-bold text-[45px] leading-[52px] text-center text-primary mt-10'>Why Choose Us</h2>
          <p className='font-inter leading-[24px] text-center lg:w-[41%] md:w-[80%]'>We are more than just a design and development agency. We are your partner in growth. By providing both top-tier services and trained professionals, we ensure that your startup has everything it needs to succeed.</p>

          <div className='flex justify-between items-center md:flex-nowrap flex-wrap lg:w-[90%] w-full mt-20'>
            <Card image='f1' title='End-to-End Solutions' />
            <Card image='f2' title='Startup-Focused' />
            <Card image='f3' title='Experienced Team' />
            <Card image='f4' title='Talent Pool' />
          </div>
        </div>
    </motion.div>
    </div>
  )
}

export default Features