import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Button from '../../components/ui-components/Button'
import { motion } from 'framer-motion'
import { handleScroll } from '../../utils/utils'
import { NavLink } from 'react-router-dom'

const HeroSection = () => {
  return (
    <motion.div initial="hidden" whileInView="visible" viewport={{once:true, amount:0.1}} transition={{delay:0.2,duration:0.5}} variants={{hidden:{opacity: 0, y: 50}, visible:{opacity: 1, y: 0}}}>
      <div className="h-[732px] bg-Hero bg-cover bg-center rounded-lg shadow-[inset_2px_2px_20px_-1px] shadow-primary">
          <Navbar />
          <div className='flex justify-center items-center h-[60%]'>
            <div className='flex flex-col justify-center items-center gap-5'>
              <h2 className='font-space font-bold md:text-[32px] text-xl md:leading-[40px] text-center text-primary'>From Concept to Launch, <br/>With the Right Team by Your Side</h2>

              <p className='text-center md:leading-[24px] font-[400] text-sm md:text-base px-1'>We turn your startup vision into reality with expert design, <br className='hidden md:block'/>development, and a team ready to propel your growth</p>

              <NavLink variant='primary' onClick={(e)=>handleScroll(e,"contact")} className="w-fit rounded-xl border-2 border-white shadow-[2px_2px_10px_1px] shadow-primary bg-primary text-white p-1">Get A Free Quote</NavLink>

              <p className='text-center leading-[24px] font-[400] text-primary'>Let's Build Together</p>
            </div>
          </div>
      </div>

      <div id='about' className="md:h-[520px] md:w-4/5 w-[90%] py-16 md:py-0 bg-[url('./assets/homepage/about.png')] bg-cover bg-center border-2 border-white shadow-[4px_4px_30px_5px] shadow-primary rounded-lg mx-auto -translate-y-36 text-white">
        <div className='flex flex-col justify-center items-center gap-12 h-4/5 opacity-90'>
          <h2 className='font-space font-bold text-[45px] leading-[52px] text-center'>Who We Are</h2>

          <p className='font-inter font-[400] leading-[24px] text-center w-[64%]'>
          We are Techovelpo, a passionate and skilled team of designers, developers, and strategic thinkers, deeply committed to empowering startups and businesses with cutting-edge software solutions. Our team understands the unique challenges and opportunities in the startup ecosystem, making us the perfect partner to help bring your vision to life.
          <br/><br/>
          From the initial concept and design phase to seamless development, deployment, and beyond, Techovelpo delivers comprehensive, end-to-end services tailored to meet your needs. Our expertise spans all stages of the product lifecycle, ensuring every project is executed with precision, innovation, and a deep commitment to quality.
          </p>
        </div>

        
      </div>
    </motion.div>
  )
}

export default HeroSection